<template>
  <div class="list-page">
    <OrderListFilter
      v-model="filterForm"
      :page="pageData.current_page"
      :uploadFilter="ok"
    />
    <el-table
      style="margin-top: 20px"
      :data="tbody"
      class="thead-light"
      v-loading="showLoading"
      stripe
      @sort-change="sortChange"
    >
      <el-table-column
        v-for="(item, index) in thead"
        :key="index"
        :label="item.label"
        :prop="item.prop"
        :min-width="item.minWidth"
        show-overflow-tooltip
        :sortable="item.sortable"
      >
        <template slot-scope="scope">
          <!-- 订单状态显示 -->
          <template
            v-if="item.prop === 'order_status' && scope.row[item.prop] !== '--'"
          >
            <ListStatusText
              :text="scope.row[item.prop]"
              :type="
                scope.row[item.prop] === '已支付'
                  ? 'success'
                  : scope.row[item.prop] === '未支付'
                  ? 'warning'
                  : scope.row[item.prop] === '已退款'
                  ? 'danger'
                  : 'info'
              "
            ></ListStatusText>
          </template>
          <!-- 普通 -->
          <template v-else>
            <span>{{ scope.row[item.prop] | placeholder }}</span>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" :width="60">
        <template slot-scope="scope">
          <el-button @click="checkRow(scope.row)" type="text">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 订单详情弹窗 -->
    <OrederDetailDialog
      v-model="openDetailDialog"
      :id="currentId"
    ></OrederDetailDialog>
    <Pagination
      :total="pageData.total"
      :page="pageData.current_page"
      :change="changPage"
    >
    </Pagination>
  </div>
</template>

<script>
import OrderListFilter from "../components/OrderListFilter";
import OrederDetailDialog from "../components/OrederDetailDialog";
import { CircleOrderList } from "../api/order-list";
import Pagination from "@/base/components/Default/Pagination";
import ListStatusText from "@/base/components/List/ListStatusText";

export default {
  name: "circle-category",
  data() {
    return {
      filterForm: {
        orderid: "",
        merchant: "",
        user_keyword: "",
        status: -1,
        category: -1,
        type: -1,
        start_time: -1,
        end_time: -1,
        page: 1,
        page_size: 15,
      },
      pageData: {},
      thead: [
        { label: "ID", prop: "user_id", minWidth: 70 },
        { label: "用户名称", prop: "nickname", minWidth: 110 },
        { label: "会内职务", prop: "title_name", minWidth: 160 },
        {
          label: "交易数额",
          prop: "price",
          minWidth: 100,
          sortable: true,
        },
        { label: "交易类型", prop: "order_type", minWidth: 80 },
        { label: "订单类型", prop: "order_category", minWidth: 150 },
        { label: "订单号", prop: "orderid", minWidth: 150 },
        { label: "订单状态", prop: "order_status", minWidth: 80 },
        { label: "商户号", prop: "merchant", minWidth: 80 },
        {
          label: "创建时间",
          prop: "create_time",
          minWidth: 160,
          sortable: true,
        },
      ],
      tbody: [],
      total: 0,
      showLoading: false,
      showAddCategory: false,
      editObj: {},
      showTransfer: false,
      transferId: "",
      // 查看订单弹窗
      openDetailDialog: false,
      // 当前操作的id
      currentId: "",
    };
  },
  components: {
    Pagination,
    OrderListFilter,
    ListStatusText,
    OrederDetailDialog,
  },
  created() {
    this.getOrderList(this.filterForm);
  },
  methods: {
    // 筛选回调
    ok(e) {
      this.filterForm = { ...this.filterForm, ...e, page: 1 };
      this.getOrderList(this.filterForm);
    },
    updateList() {
      this.getOrderList({
        page: this.pageData.current_page,
        ...this.filterForm,
      });
    },
    //表格排序
    sortChange(column) {
      this.filterForm.is_desc = !column.order
        ? -1
        : column.order === "ascending"
        ? 0
        : 1;
      this.filterForm.order_by = column.prop;
      this.getOrderList(this.filterForm);
    },
    changPage(data) {
      this.filterForm = { ...this.filterForm, ...data };
      this.getOrderList(this.filterForm);
    },
    getOrderList(requestData) {
      this.showLoading = true;
      this.pageData = {};
      CircleOrderList(requestData)
        .then((res) => {
          const { data } = res;
          this.tbody = data.data;
          console.log(this.tbody);
          this.pageData = data;
          this.showLoading = false;
        })
        .catch((err) => {
          this.showLoading = false;
        });
    },
    // 查看订单详情
    checkRow(row) {
      // 赋值当前id
      this.currentId = row.id;
      // 打开订单弹窗
      this.openDetailDialog = true;
    },
  },
};
</script>

<style scoped lang="scss"></style>
