<template>
  <el-dialog
    title="订单详情"
    width="750px"
    class="dialog-vertical"
    :visible.sync="openDialog"
    @open="dialogOpen"
    @close="cancel"
  >
    <div v-loading="dialogLoading">
      <el-row :gutter="20">
        <el-col :span="8"
          >会员名称 :
          {{ detailList.user ? detailList.user.nickname : "-" }}</el-col
        >
        <el-col :span="16"
          >会内职务 :
          {{ detailList.user ? detailList.user.title_name : "-" }}</el-col
        >
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8">交易数额 : {{ detailList.price }}（元）</el-col>
        <el-col :span="8">交易类型 : 金额</el-col>
        <!-- <el-col :span="16"
          >活动名称 :
          {{ detailList.activity ? detailList.activity.name : "-" }}</el-col
        > -->
      </el-row>
      <el-divider></el-divider>

      <el-row :gutter="20">
        <el-col :span="8">订单类型 : {{ detailList.order_category }}</el-col>
        <el-col :span="8">订单号 : {{ detailList.orderid }}</el-col>
        <el-col :span="8">订单状态 : {{ detailList.order_status }}</el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8" v-if="detailList.status == 3"
          >退款金额 : {{ detailList.refund_price }}（元）</el-col
        >
        <el-col :span="16"
          >备注 :
          {{
            detailList.refund_remarks ? detailList.refund_remarks : "-"
          }}</el-col
        >
      </el-row>
      <el-divider></el-divider>

      <el-row :gutter="20">
        <!-- 已取消 -->
        <el-col :span="8" v-if="detailList.status == 2"
          >取消时间 : {{ detailList.cancel_time }}</el-col
        >
        <!-- 已退款 -->
        <el-col :span="8" v-else-if="detailList.status == 3">
          <div class="el-title">退款时间 :</div>
          {{ detailList.refund_time }}
        </el-col>
        <!-- 已支付 -->
        <el-col :span="8" v-else-if="detailList.status == 0"
          >支付时间 :
          {{ detailList.pay_time ? detailList.pay_time : "-" }}</el-col
        >
        <el-col :span="8">商户号 : {{ detailList.merchant }}</el-col>
        <el-col :span="8">创建时间 : {{ detailList.create_time }}</el-col>
      </el-row>
    </div>
    <el-divider></el-divider>
    <template #footer>
      <el-button size="small" type="primary" @click="cancel">确认</el-button>
    </template>
  </el-dialog>
</template>
<script>
import { CircleOrderDetail } from "../api/order-list";
export default {
  model: {
    prop: "openDialog",
    event: "changeOpenDialog",
  },
  props: {
    // 开启弹窗的值
    openDialog: {
      type: Boolean,
      required: true,
    },
    // id
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      // 详情列表
      detailList: [],
      dialogLoading: false,
    };
  },
  methods: {
    // 打开弹窗回调
    dialogOpen() {
      this.dialogLoading = true;
      CircleOrderDetail({ id: this.id })
        .then((res) => {
          this.detailList = res.data;
          this.dialogLoading = false;
        })
        .catch((err) => {
          this.dialogLoading = false;
        });
    },

    cancel() {
      this.$emit("changeOpenDialog", false);
    },
  },
  computed: {},
};
</script>
<style>
.el-col-8 {
  height: 60px;
  display: flex;
  align-items: center;
}
.el-col-16 {
  height: 60px;
  display: flex;
  align-items: center;
}
.el-divider--horizontal {
  margin: 0;
}
.el-title {
  width: 70px;
}
</style>
