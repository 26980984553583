import api from "@/base/utils/request";
import download from "@/base/utils/download";

// 资源圈订单列表
export const CircleOrderList = data => {
  return api({
    url: "/admin/cyc_circle/order/index",
    method: "post",
    data
  });
};
// 供求订单查看详情
export const CircleOrderDetail = data => {
  return api({
    url: "/admin/cyc_circle/order/detail",
    method: "post",
    data
  });
};

// 资源订单列表导出
export const exportList = data => {
  return download({
    url: "/admin/cyc_circle/order/export",
    method: "post",
    data,
    download: true
  });
};