<template>
  <LayoutFilter :onFilter="ok" :onReset="resetForm"
    :toggleCollapseEnable=" filterForm.tab !== 'yesblack' && filterForm.tab !== 'noblack'?true:false"
    :onExport="onExport">
    <el-form :model="form" :rules="rules" ref="logFilterForm" label-width="70px" size="medium"
      class="filter-from">
      <!-- 模块&用户名&搜索 -->
      <div class="filter-item" v-if="filterForm.tab !== 'yesblack' && filterForm.tab !== 'noblack'">
        <el-form-item label="用户名称：" prop="user_keyword">
          <el-input v-model="form.user_keyword" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="订单类型：" prop="category">
          <el-select v-model="category" placeholder="请选择订单类型">
            <el-option v-for="item in categoryList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="交易类型：" prop="type">
          <el-select v-model="type" placeholder="请选择订单类型">
            <el-option v-for="item in typeOptions" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="filter-item">
        <el-form-item label="支付状态：" prop="status">
          <el-select v-model="status" placeholder="请选择支付状态">
            <el-option v-for="item in statusOptions" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单号：" prop="orderid">
          <el-input v-model="form.orderid" placeholder="请输入订单号"></el-input>
        </el-form-item>
        <el-form-item label="商户号：" prop="merchant">
          <el-input v-model="form.merchant" placeholder="请输入商户号"></el-input>
        </el-form-item>
      </div>
      <div class="filter-item" v-if="form.tab !== 'user'">
        <DatePeriodPicker label="创建时间：" start-prop="start_time" :start-time.sync="form.start_time"
          end-prop="end_time" :end-time.sync="form.end_time" />
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
import { exportList } from "../api/order-list";
export default {
  model: {
    prop: "filterForm",
    event: "updateFilter",
  },
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    filterForm: {
      type: Object,
    },
    page: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      rules: {},
      statusOptions: [
        { name: "已支付", id: 0 },
        { name: "未支付", id: 1 },
        { name: "已取消", id: 2 },
        { name: "已退款", id: 3 },
        { name: "退款中", id: 4 },
      ],
      typeOptions: [
        { name: "金额", id: 0 },
        { name: "积分", id: 1 },
      ],
      categoryList: [
        { name: "置顶付费", id: 2 },
        { name: "内容付费", id: 3 },
      ],
    };
  },
  methods: {
    //重置表单
    resetForm() {
      this.$refs["logFilterForm"].resetFields();
      this.uploadFilter(this.form);
    },
    // 导出
    onExport() {
      const data = { ...this.filterForm, page: this.page };
      exportList(data).then().catch();
    },
    //筛选
    ok() {
      this.uploadFilter(this.form);
    },
  },
  computed: {
    form: {
      get() {
        return this.filterForm;
      },
      set(val) {
        this.$emit("updateFilter", val);
      },
    },
    type: {
      get() {
        return this.form.type == -1 ? "" : this.form.type;
      },
      set(val) {
        this.form.type = val;
      },
    },
    status: {
      get() {
        return this.form.status == -1 ? "" : this.form.status;
      },
      set(val) {
        this.form.status = val;
      },
    },
    category: {
      get() {
        return this.form.category == -1 ? "" : this.form.category;
      },
      set(val) {
        this.form.category = val;
      },
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>
